import React from "react";
import PropTypes from "prop-types";
import { AB_TESTS_STATUS, AB_TESTS_ID_TYPE } from "app/constants";

export class ABTestExperiment extends React.Component {
	getBackUpElement() {
		/* console.warn(`ABTest BackUpElement has been triggered; campaign #${this.props.campaignID}`); */

		return React.Children.toArray(this.props.children).find(
			child => child.props.variationID === AB_TESTS_ID_TYPE.BACKUP
		);
	}

	getActiveElement() {
		const { activeVariationID } = this.props;

		if (!activeVariationID) {
			return this.getBackUpElement();
		}

		const activeElement = React.Children.toArray(this.props.children).find(
			child => child.props.variationID === activeVariationID
		);

		return activeElement || this.getBackUpElement();
	}

	getPlaceHolderElement() {
		const placeHolderElement = React.Children.toArray(this.props.children).find(
			child => child.props.variationID === AB_TESTS_ID_TYPE.PLACEHOLDER
		);

		return placeHolderElement || this.getBackUpElement();
	}

	render() {
		const { status } = this.props;

		let variantElement;

		switch (status) {
			case AB_TESTS_STATUS.LOADING:
				variantElement = this.getPlaceHolderElement();
				break;
			case AB_TESTS_STATUS.SUCCESS:
				variantElement = this.getActiveElement();
				break;
			case AB_TESTS_STATUS.FAILURE:
			default:
				variantElement = this.getBackUpElement();
				break;
		}

		if (!variantElement) {
			throw new Error(`ABTest BackUpElement is missing; campaign #${this.props.campaignID}`);
		}

		return variantElement;
	}
}

ABTestExperiment.propTypes = {
	campaignID: PropTypes.string.isRequired,
	status: PropTypes.any,
	activeVariationID: PropTypes.string,
};
export default ABTestExperiment;
