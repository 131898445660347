import { connect } from "react-redux";
import ABTestExperiment from "./ABTestExperiment";
import { getABTestsStatus, getActiveVariationID } from "app/reducers/abTestsSelector";

function mapStateToProps(state, ownProps) {
	return {
		status: getABTestsStatus(state),
		activeVariationID: getActiveVariationID(state, ownProps.campaignID),
	};
}

export default connect(mapStateToProps)(ABTestExperiment);
