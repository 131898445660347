/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";

export class ABTestVariant extends React.Component {
	render() {
		return React.isValidElement(this.props.children) ? (
			this.props.children
		) : (
			<span> {this.props.children} </span>
		);
	}
}

ABTestVariant.propTypes = {
	variationID: PropTypes.string.isRequired,
};
